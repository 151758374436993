<template>
  <div>
    <van-field
      readonly
      clickable
      is-link
      :name="valName"
      class="van-iput"
      :value="modelVal"
      placeholder="请选择变量内容"
      @click="showPicker = true"
      :rules="[{ required: isrequireFlag, message: `${message}不能为空` }]"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'FillFileDate',
    props: {
      model: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      optionList: {
        type: Array,
        default: null,
      },
      requireFlag: {
        type: Number,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        modelVal: this.model,
        valName: this.name,
        showPicker: false,
        columns: this.optionList,
        isrequireFlag: this.requireFlag,
        message: this.title,
      }
    },
    mounted() {},
    methods: {
      onConfirm(key) {
        this.modelVal = key
        this.showPicker = false
      },
    },
  }
</script>

<style></style>
