<template>
  <div>
    <van-field
      v-model="modelVal"
      is-link
      readonly
      :name="valName"
      placeholder="请选择变量内容"
      class="van-iput"
      @click="showPicker = true"
      :rules="[{ required: isrequireFlag, message: `${message}不能为空` }]"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        :formatter="formatter"
        type="date"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'FillFileDate',
    props: {
      model: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      requireFlag: {
        type: Number,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        modelVal: this.model,
        valName: this.name,
        showPicker: false,
        isrequireFlag: this.requireFlag,
        message: this.title,
      }
    },
    mounted() {},
    methods: {
      formatter(type, val) {
        if (type === 'year') {
          this.value1 = val
          return `${val}年`
        }
        if (type === 'month') {
          this.value2 = val
          return `${val}月`
        }
        if (type === 'day') {
          this.value3 = val
          return `${val}日`
        }
        return val
      },
      onConfirm() {
        this.modelVal = `${this.value1}年${this.value2}月${this.value3}日`
        this.showPicker = false
      },
    },
  }
</script>

<style></style>
