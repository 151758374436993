<template>
  <div>
    <van-switch v-model="modelVal" @change="switchChange">
      <div class="ssssss"></div>
    </van-switch>
  </div>
</template>

<script>
  export default {
    name: 'FillFileSwitch',
    props: {
      model: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        modelVal: this.model,
      }
    },
    methods: {
      switchChange(val) {
        this.$emit('switchChange', val)
      },
    },
  }
</script>

<style lang="less" scoped>
  .van-switch {
    width: 69px;
    height: 22px;
    background: #c4c7c6;
    display: flex;
    align-items: center;
    position: relative;
    &::after {
      position: absolute;
      content: '由我填';
      right: 8px;
      top: 1px;
      width: 36px;
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 20px;
    }
    /deep/ .van-switch__node {
      width: 19px;
      height: 19px;
    }
    /deep/ .van-switch__node {
      top: 1.5px;
    }
  }
  .van-switch--on {
    width: 69px;
    height: 22px;
    background: #c4c7c6;
    display: flex;
    align-items: center;
    background: #1676ff;
    position: relative;
    &::before {
      position: absolute;
      content: '由我填';
      left: 8px;
      top: 1px;
      width: 36px;
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 20px;
    }
    &::after {
      content: '';
    }
    /deep/ .van-switch__node {
      left: 17px;
    }
  }
</style>
