<template>
  <div>
    <van-field
      v-model="modelVal"
      :name="valName"
      placeholder="请填写变量内容"
      class="van-iput"
      clearable
      :rules="rules"
      :error-message="errorMessage"
    />
  </div>
</template>

<script>
  export default {
    name: 'FillFileText',
    props: {
      model: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      requireFlag: {
        type: Number,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      min: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        modelVal: this.model,
        valName: this.name,
        minLength: this.min,
        maxLength: this.max,
        errorMessage: '',
        rules: [
          {
            required: this.requireFlag,
            message: `${this.title}不能为空`,
          },
          {
            validator: this.validator,
          },
        ],
      }
    },
    mounted() {},
    methods: {
      validator(rule, value, callback) {
        let lengths = value.length
        if (lengths <= this.minLength || lengths >= this.maxLength) {
          this.errorMessage = `长度在 ${this.minLength} 到 ${this.maxLength} 个字符`
        } else {
          this.errorMessage = ''
        }
      },
    },
  }
</script>

<style></style>
