<template>
  <div class="big_div">
    <!-- 头 -->
    <div class="header">
      <van-dropdown-menu>
        <van-dropdown-item v-model="arrFileVal" @change="arrFileChange" :options="arrFile" />
      </van-dropdown-menu>
    </div>
    <!-- 内容 -->
    <div class="content">
      <van-form ref="form" @submit="onSubmit">
        <div class="content-div" v-for="(item, index) in fileListArr" :key="item.fileId">
          <div style="width: fit-content">
            <div class="title-div">
              文件{{ index + 1 }}：{{ item.fileName }}
            </div>
          </div>
          <div class="form-div">
            <van-cell-group inset v-for="(itemIndex, indexIndex) in item.comVarParamList" :key="indexIndex">
              <van-cell style="margin-top: 21px;" center :title="itemIndex.varTitle">
                <template #right-icon>
                  <FillFileSwitch @switchChange="val => switchChange(val, itemIndex)" />
                </template>
              </van-cell>
              <!-- 数据类型 8-文本 2-数字 3-日期 4-选项 -->
              <template v-if="itemIndex.dataType === 8">
                <van-field readonly v-if="itemIndex.fillPartnerIndex" v-model="switchText" placeholder="请填写变量内容"
                  class="van-iput" />
                <template v-else>
                  <FillFileText :model="itemIndex.varValue" :name="itemIndex.variableId" :title="itemIndex.varTitle"
                    :requireFlag="itemIndex.requireFlag" :max="itemIndex.maxLength" :min="itemIndex.minLength" />
                </template>
              </template>
              <template v-else-if="itemIndex.dataType === 2">
                <van-field readonly v-if="itemIndex.fillPartnerIndex" v-model="switchText" placeholder="请填写变量内容"
                  class="van-iput" />
                <template v-else>
                  <FillFileNum :model="itemIndex.varValue" :name="itemIndex.variableId" :title="itemIndex.varTitle"
                    :requireFlag="itemIndex.requireFlag" :max="itemIndex.maxLength" :min="itemIndex.minLength" />
                </template>
              </template>
              <template v-else-if="itemIndex.dataType === 3">
                <van-field readonly v-if="itemIndex.fillPartnerIndex" v-model="switchText" placeholder="请填写变量内容"
                  class="van-iput" />
                <template v-else>
                  <FillFileDate :model="itemIndex.varValue" :title="itemIndex.varTitle" :name="itemIndex.variableId"
                    :requireFlag="itemIndex.requireFlag" />
                </template>
              </template>
              <template v-else-if="itemIndex.dataType === 4">
                <van-field readonly v-if="itemIndex.fillPartnerIndex" v-model="switchText" placeholder="请填写变量内容"
                  class="van-iput" />
                <template v-else>
                  <FillFileOption :model="itemIndex.varValue" :name="itemIndex.variableId" :title="itemIndex.varTitle"
                    :optionList="itemIndex.optionList" :requireFlag="itemIndex.requireFlag" />
                </template>
              </template>
            </van-cell-group>
          </div>
        </div>
      </van-form>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <!-- 选择参与方 -->
      <van-picker show-toolbar :columns="switchList" @confirm="v => onConfirm(v)" @cancel="onCancel" />
    </van-popup>
    <div class="zhan"></div>
    <!-- 尾部 -->
    <div class="footer">
      <div class="two-div">
        <van-button class="file-btn" @click="viewClick">查看文件</van-button>
        <van-button class="next-btn" type="submit" @click="nextClick">下一步</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import contractBusiness from '@/api/contractBusiness'
import FillFileText from './components/FillFileText.vue'
import FillFileNum from './components/FillFileNum.vue'
import FillFileDate from './components/FillFileDate.vue'
import FillFileOption from './components/FillFileOption.vue'
import FillFileSwitch from './components/FillFileSwitch.vue'

export default {
  components: {
    FillFileText,
    FillFileNum,
    FillFileDate,
    FillFileOption,
    FillFileSwitch,
  },
  data() {
    return {
      arrFileVal: '0',
      arrFile: [{ text: '全部文件', value: '0' }],
      addressChecked: true,
      fileList: [],
      showPicker: false,
      partnerList: [],
      switchList: [],
      data: {},
      active: {},
      switchText: '',
    }
  },
  computed: {
    // 切换文件
    fileListArr() {
      if (this.arrFileVal !== '0') {
        let arr = []
        arr = this.fileList.filter(item => {
          return this.arrFileVal === item.fileId
        })
        return arr
      }
      return this.fileList
    },
  },
  mounted() {
    this.getcontractBusiness()
  },
  methods: {
    // 获取填写内容
    async getcontractBusiness() {
      try {
        let data = await contractBusiness.draft({
          masterId: this.$route.query.masterId,
        })
        this.data = data
        this.fileList = data.fileList
        this.partnerList = data.partnerList
        this.fileList.forEach((item, index) => {
          let obj = {
            text: `文件${index + 1}:${item.fileName}`,
            value: item.fileId,
          }
          this.arrFile.push(obj)
        })
        this.switchList = data.partnerList.map(item => ({
          text: `${item.defaultName}（${item.customName}）`,
          value: `${item.partnerIndex}`,
        }))
      } catch (res) {
        console.log('res: ', res)
      }
    },
    // 选择文件
    arrFileChange(key) {
      this.arrFileVal = key
    },
    // 切换开关状态
    switchChange(val, key) {
      this.active = key
      if (val) {
        key.fillPartnerIndex = null
      } else {
        this.active = key
        this.showPicker = true
      }
    },
    // 取消切换开关状态
    onCancel() {
      this.showPicker = false
    },
    // 变量内容是由谁填写
    onConfirm(val) {
      // console.log('val: ', val)
      this.switchText = val.text
      this.active.fillPartnerIndex = val.value
      this.showPicker = false
    },
    // 提交表单
    async onSubmit(values) {
      try {
        this.$toast.loading({
          message: '提交中...',
          forbidClick: true,
          duration: 0,
        })

        Object.keys(values).forEach(key => {
          this.fileList.forEach(items => {
            let obj = items.comVarParamList.filter(item => {
              return item.variableId === key
            })
            if (obj.length > 0) {
              obj[0].varValue = values[key]
            }
          })
        })
        this.data.fileList = this.fileList

        let data = await contractBusiness.saveWithStatus({
          ...this.data,
          launchSign: 1,
          step: 2,
        })

        if (data.status === 200) {
          this.$toast.clear()
          this.$router.push({
            name: 'SignDetail',
            query: {
              businessId: this.data.businessId,
            },
          })
        } else {
          this.$toast(data.msg)
          this.$toast.clear()
        }
      } catch (res) {
        this.$toast.clear()
        console.log('res: ', res)
      }
    },
    // 点击下一步
    nextClick() {
      this.$refs.form.submit()
    },
    // 查看文件
    viewClick() {
      this.$router.push({
        name: 'FillView',
        query: {
          masterId: this.$route.query.masterId,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.big_div {
  background: #f5f5f5;
  // max-height: vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    width: 375px;
    height: 48px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    /deep/ .van-dropdown-menu__item {
      width: 375px;
      height: 48px;
    }

    /deep/ .van-dropdown-menu__title--active {
      color: #0075ff;
    }

    /deep/ .van-dropdown-item__option--active {
      color: #0075ff;
    }

    /deep/ .van-dropdown-item__option--active .van-dropdown-item__icon {
      color: #0075ff;
    }

    /deep/ .van-dropdown-menu__bar {
      position: relative;

      &::after {
        position: absolute;
        top: 21px;
        right: 20px;
        // margin-top: -5px;
        border: 3px solid;
        border-color: transparent transparent #434751 #434751;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0.8;
        content: '';
      }

      .van-dropdown-menu__title {
        position: absolute;
        left: 8px;

        // top: 16px;
        &::after {
          display: none;
        }

        .van-ellipsis {
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: center;
          color: #434751;
        }
      }
    }

    /deep/ .van-dropdown-menu__bar--opened {
      position: relative;

      &::after {
        position: absolute;
        top: 21px;
        right: 20px;
        border: 3px solid;
        border-color: transparent transparent #0075ff #0075ff;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0.8;
        content: '';
        transform: rotate(135deg);
      }

      .van-dropdown-menu__title {
        .van-ellipsis {
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: center;
          color: #0075ff;
        }
      }
    }
  }

  .content {
    overflow: auto;
    // height: 100vh;
    margin: 0 auto;
    padding-bottom: 14px;

    .content-div {
      width: 347px;
      // height: 199px;
      background: #ffffff;
      margin-top: 14px;
      padding-bottom: 19px;

      .title-div {
        height: 26px;
        background: #414c5c;
        border-radius: 2px 0px 0px 2px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 26px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 11px 0px 12px;
      }

      .form-div {
        .van-cell__title {
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          color: #111a34;
        }

        /deep/ .van-cell-group {
          width: 312px;
          margin: 0 auto;
          border-radius: 0;

          .van-iput {
            padding-bottom: 6px;
            position: relative;

            &::before {
              position: absolute;
              bottom: 0px;
              content: '';
              width: 323px;
              border: 0.5px solid #cdcdce;
            }

            .van-cell__value {
              padding-bottom: 6px;
            }
          }

          .van-cell {
            padding: 0;
            margin-top: 8px;

            .van-field__control {
              font-size: 13px;
              font-weight: 400;
              text-align: left;
              color: #111a34;
            }

            &::after {
              display: none;
            }
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .zhan {
    height: calc(54px + constant(safe-area-inset-bottom));
    height: calc(54px + env(safe-area-inset-bottom));
  }

  .footer {
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6px 12px;
    padding-bottom: calc(6px + constant(safe-area-inset-bottom));
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    z-index: 1;

    .two-div {
      width: 344px;
      height: 44px;
      // background: pink;
      display: flex;
      justify-content: space-between;

      .file-btn {
        width: 166px;
        height: 44px;
        background: #ffffff;
        border: 0.5px solid #1676ff;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #1676ff;
        line-height: 22px;
      }

      .next-btn {
        width: 166px;
        height: 44px;
        background: #0075ff;
        border: 0.5px solid #1676ff;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #fff;
        line-height: 22px;
      }
    }
  }
}
</style>
